const headLine = ({ text, classes, image }) => {
  return (
    <div>
      <h2 className={classes}>
        {image && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={image}
            alt=""
            className={"mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl"}
          />
        )}
        {text}
      </h2>
    </div>
  );
};

export default headLine;
